import BaseMethods from '../BaseMethods';
import {getData} from '../utils/DataHandler';

export default new (class extends BaseMethods {
    endpointRoute = '/api/v2/projects/:projectId/additional-documents';

    async store(projectId, data) {
        const route = this.replaceRouteBindings({projectId});
        
        return this.asFormData().post(route, data).then(getData);
    }
})();
