<template>
    <ConfirmDialog ref="confirmDialog"/>
    <CRow style="margin-bottom: 25px">
        <CCol xs="12" style="margin-bottom: 25px; display: flex; align-items: center; justify-content: space-between">
            <h3 class="projectTemplateCollection__tableHeader">
                <CIcon name="cil-folder-new" style="margin: 0 8px 0 5px; transform: scale(1.5)"/>
                {{ label }}
            </h3>
            <BulkAction :folder-id="folderId" :default-name="defaultName" @addTemplate="addTemplate"
                        @update="$emit('update')"/>
        </CCol>
        <CCol xs="12">
            <SmartTable :items="templateCategories" :columns="dataFields" infinity :loading="loading" dropdown
                        :draggable="true" @event-drag="eventDrag" @event-drop="eventDrop">
                <template #template_name="{ item }">
                    <a href="#" class="d-flex text-decoration-underline w-100"
                       @click.prevent="toggleDocumentPreviewer(item.template_id, item.upload?.extension)">
                        <Icon :type="item.type" class="mr-2"/>
                        <span class="ellipsis w-100">{{ item.template_name }}</span>
                    </a>
                </template>
                <template #button="{ item }">
                    <div class="d-flex justify-around">
                        <CButton variant="ghost" @click="handleDelete(item)">
                            <CIcon name="cil-trash"/>
                        </CButton>
                    </div>
                </template>
            </SmartTable>
        </CCol>
    </CRow>
    <DocumentPreviewer :visible="documentPreviewer.visible" type="templates" :type-id="documentPreviewer.typeId"
                       :extension="documentPreviewer.extension" @closed="toggleDocumentPreviewer(null, null)"/>
</template>

<script>
import Icon from '@/components/Template/Icon';
import ConfirmDialog from '@/components/Modals/ConfirmDialog';
import BulkAction from './BulkAction';
import {permissionError, updatePlan} from '@/services/Notify/Toasts';
import Gate from '@/services/Gate/Gate';
import DocumentPreviewer from "@/components/Documents/DocumentPreviewer.vue";

export default {
    components: {DocumentPreviewer, Icon, ConfirmDialog, BulkAction},
    inject: ['toast'],
    props: {
        data: {type: Object, default: {}},
        label: {type: String, default: ''},
        defaultName: {type: String, default: ''},
        folderId: {type: Number, default: 0},
        loading: {type: Boolean, default: false},
    },
    emits: ['delete', 'addTemplate', 'update', 'moveTemplate'],
    data() {
        return {
            key: 0,
            sort: {},
            dataFields: [
                {
                    key: 'template_name',
                    label: 'Template Name',
                    _style: '',
                    sorter: false,
                },
                {key: 'task_name', label: 'Task Name', _style: '', sorter: false},
                {key: 'type', label: 'Contract Type', _style: '', sorter: false},
                {
                    key: 'updated_at',
                    label: 'Modified',
                    _style: '',
                    sorter: false,
                    format: (value, item) =>
                        `${item.updated_at ? this.$BtoF({value: item.updated_at}) : 'N/A'} - ${
                            item.modified_by ? item.modified_by?.full_name : 'N/A'
                        }`,
                },
            ],
            templateCategories: [
                {
                    title: 'Primary Contract Package',
                    items: this.data.primary_contracts,
                    bind: {'data-flow': 'primary'},
                },
                {
                    title: 'Flowdown',
                    items: this.data.secondary_contracts,
                    bind: {'data-flow': 'secondary'},
                },
            ],
            documentPreviewer: {
                visible: false,
                extension: null,
                typeId: null,
            },
            perPage: 6,
            tempDrag: null,
        };
    },
    watch: {
        perPage() {
            this.key++;
        },
    },
    methods: {
        async handleDelete(item) {
            const check = await Gate.can('editOrCreate', 'project', this.$route.params.id);
            if (!check) {
                if (this.$store.getters.isSubPlan) this.$notify(updatePlan);
                if (this.$store.getters.isGeneralPlan) this.$notify(permissionError);
                return false;
            }

            await this.$refs.confirmDialog
                .confirm({
                    text: 'Are you sure you want to delete this template?',
                    cancelText: 'Cancel',
                    confirmText: 'Delete',
                    reverse: true,
                })
                .then((res) => {
                    if (res) this.$emit('delete', this.folderId, item.pivot_id);
                });
        },
        addTemplate(template) {
            this.$emit('addTemplate', {folderId: this.folderId, template: template});
            this.toast('success', `Task ${template.template_name} has been added to the project`);
        },
        getTr(el) {
            return el.tagName === 'TR' ? el : el.parentElement ? this.getTr(el.parentElement) : null;
        },
        eventDrag(event) {
            let el = this.getTr(event.target);
            if (el?.dataset?.flow) {
                this.tempDrag = el.dataset;
            }
        },
        eventDrop(event) {
            let el = this.getTr(event.target);
            if (el?.dataset?.flow) {
                if (this.tempDrag?.flow !== el.dataset.flow)
                    this.$emit('moveTemplate', {
                        template_id: this.tempDrag.id,
                        flow: el.dataset.flow,
                        folder_id: this.folderId,
                    });
            }
        },
        toggleDocumentPreviewer(taskId, extension) {
            this.documentPreviewer.typeId = taskId;
            this.documentPreviewer.extension = extension;
            this.documentPreviewer.visible = !this.documentPreviewer.visible;
        },
    },
};
</script>

<style lang="scss"></style>
