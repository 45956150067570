<template>
    <CDropdown class="mb-1">
        <CDropdownToggle v-if="isMobile" class="clear">
            <CIcon name="cilPlus"/>
        </CDropdownToggle>
        <CDropdownToggle v-else color="primary" variant="outline">Add Document</CDropdownToggle>

        <CDropdownMenu>
            <CDropdownItem @click="handleChange('modalLibraryPrimary')">Add from library to Primary</CDropdownItem>
            <CDropdownItem @click="handleChange('modalLibraryFlowDown')">Add from library to Flowdown</CDropdownItem>
        </CDropdownMenu>
    </CDropdown>
    <FromLibrary v-model="modalLibraryPrimary" :except-library-type="['label']"
                 @addTemplate="template => addTemplate({...template, flow: 'primary_contracts'})"/>
    <FromLibrary v-model="modalLibraryFlowDown" :except-library-type="['label']"
                 @addTemplate="template => addTemplate({...template, flow: 'secondary_contracts'})"/>
</template>

<script>
import FromLibrary from '@/components/Template/Add/FromLibrary'
import {permissionError, updatePlan} from '@/services/Notify/Toasts'
import Gate from '@/services/Gate/Gate'

export default {
    name: 'ContractBulkAction',
    components: {FromLibrary},
    props: {
        folderId: {type: Number, default: null},
        defaultName: {type: String, default: ''},
    },
    emits: ['addTemplate', 'update'],
    computed: {
        isMobile() {
            return this.windowWidth < 576
        },
    },
    data() {
        return {
            modalLibraryPrimary: false,
            modalLibraryFlowDown: false,
            windowWidth: window.innerWidth,
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth
        })
    },
    methods: {
        async handleChange(modelName) {
            if (await this.checkPermission()) {
                this[modelName] = true
            }
        },
        async checkPermission() {
            const check = await Gate.can('editOrCreate', 'project', this.$route.params.id)
            if (!check) {
                if (this.$store.getters.isSubPlan) this.$notify(updatePlan)
                if (this.$store.getters.isGeneralPlan) this.$notify(permissionError)
            }
            return check
        },
        addTemplate(template) {
            this.$emit('addTemplate', template)
        },
        updateData(template) {
            this.$emit('update', template)
        },
    },
}
</script>

<style lang="scss" scoped>
.clear {
    box-shadow: none !important;

    &:after {
        display: none;
    }
}
</style>
